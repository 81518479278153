
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {DateTime, Settings} from 'luxon';

    import TheFooter from './components/layout/TheFooter.vue';
    import TheHeader from './components/layout/TheHeader.vue';
    import TheDrawer from './components/layout/TheDrawer.vue';
    import Maintenance from './views/Maintenance.vue';
    import {AnimalModule, CalendarModule} from '@/store/modules';

    const snackbarNamespace = namespace('snackbar');

    @Component({
        components: {
            TheFooter,
            TheHeader,
            TheDrawer,
            Maintenance,
        },
    })
    export default class App extends Vue {
        public context = process.env.VUE_APP_CONTEXT;
        public maintenanceTime = process.env.VUE_APP_CONTEXT_TIMER;
        public remainingTime: number = Number(0);
        public remaningSnack: boolean = true;

        public footerRoutes: string[] = [
            'home',
            'about',
            'faq',
            'terms',
            'contact',
            'search',
            'veterinarian',
            'organization',
        ];

        @snackbarNamespace.Getter('showSnackbar')
        public showSnackbarGetter!: boolean;

        @snackbarNamespace.Getter('snackbarMessage')
        public snackbarMessage!: string;

        @snackbarNamespace.Getter('snackbarColor')
        public snackbarColor!: string;

        @snackbarNamespace.Action('hide')
        public hideSnackbar!: () => void;

        get showSnackbar() {
            return this.showSnackbarGetter;
        }

        set showSnackbar(value: boolean) {
            if (!value) {
                this.hideSnackbar();
            }
        }

        private drawer: boolean = false;

        private created() {
            // clear and load initial data
            const animalModule = getModule(AnimalModule, this.$store);
            const calendarModule = getModule(CalendarModule, this.$store);
            const isSamsungBrowser = navigator.userAgent.indexOf('SamsungBrowser') > - 1;

            animalModule.fetchTypes();
            animalModule.fetchSpecies();
            animalModule.fetchBreeds();
            calendarModule.fetchPublicReasons();

            // Seems like Samsung Browser can't properly detect the correct locale
            if (isSamsungBrowser) {
                Settings.defaultLocale = navigator.language;
            }
            this.context = process.env.VUE_APP_CONTEXT;
        }

        public mounted() {
            if (this.context === 'maintenance' && this.remainingTime >= 0) {
                this.startTimerReminder();
            }
        }

        public startTimerReminder() {
            this.timerReminder();
            setTimeout(() => {
                if (this.remainingTime > 0) {
                    this.startTimerReminder();
                }
            }, 5000);
        }

        public timerReminder() {
            const currentDate = new Date();
            const maintenanceTime = new Date(this.maintenanceTime);
            const differenceInMillis = maintenanceTime.getTime() - currentDate.getTime();
            this.remainingTime = Math.floor(differenceInMillis / 1000 / 60);

            if (this.remainingTime <= 0) {
                this.remainingTime = 0;
            }
        }
    }
